// NOSONAR
// let eslint know about our globals defined elsewhere.
/* global
isIos:readonly,
CryptoJS:readonly,
insuranceOffer:writable,
showDesktopAlertCallback:readonly,
goToTicketList:readonly,
isInsuranceOfferedPaypal:readonly,
isInsuranceOfferedApplePay:readonly,
disableScWarning:readonly,
clearHostedFields:readonly,
initNewCreditCardHostedFields:readonly,
handleNoTickets:readonly,
isInsuranceOfferedCreditCard:readonly,
isCreditCardSelected:readonly,
isPaypalSelected:readonly,
isApplePaySelected:readonly
*/

window.footerUrl = '/v3/content/footerCheckout.htm'
window.hasStoredCart = false
window.INSTANT_DOWNLOAD = 18
window.EMAIL_DELIVERY = 11
window.paymentFieldsRemoved = false

// reset page elements for browsers that do not perform a full reload when the back button was pressed
$(window).bind('pageshow', function(event) {
    if (event.originalEvent.persisted) {
        resetSubmitButton()
        clearHiddenActionFields()
    }
})

function clearHiddenActionFields() {
    $('input[name="updateQuantity"]').remove()
    $('input[name="applyPromo"]').remove()
    $('input[name="verifyWithPaypal"]').remove()
    $('input[name="updateDelivery"]').remove()
}

function resetSubmitButton() {
    if ($('#checkoutSubmit').prop('disabled') === true) {
        const origValue = $('#checkoutSubmit').data('orig-value')
        if (typeof origValue !== 'undefined') {
            $('#checkoutSubmit').val(origValue)
        }
        $('#checkoutSubmit').prop('disabled', false)
    }
}

let pricingExpanded = false
// This appears to never be used, unless it's used by a partner somewhere.
// eslint-disable-next-line no-unused-vars
function togglePricingInfo(elm) {
    $(elm).next().toggle()
    try {
        if (!pricingExpanded) {
            pricingExpanded = true
        }
    } catch (e) {
    }
}

function shouldUseDesktopAlertCallback() {
    // eslint-disable-next-line eqeqeq
    return (jQuery.browser.mobile || (typeof showDesktopAlertCallback !== 'undefined' && showDesktopAlertCallback == true)) &&
        $('#deliveryMethod').val().length > 0
}

function handlePriceAlert() {
    let alertCallBack
    try {
        if (shouldUseDesktopAlertCallback()) {
            clearTimeout(window.totalAlertTimeout)
            window.totalAlertTimeout = null
            alertCallBack = function() {
                $('#deliveryMethodAlert').fadeIn('500')
                window.totalAlertTimeout = setTimeout(function() {
                    $('#deliveryMethodAlert').fadeOut('500')
                }, 4000)
            }
        }

        storeCart($('#email').val(), $('#wizardKey').val(), null, 'add_shipping_info')
    } catch (e) {
    }

    const requestData = []
    requestData.push({
        name: 'updateDelivery',
        value: 'true'
    })
    // Add wizard key when chrome autofill is used
    requestData.push({
        name: 'wizardKey',
        value: $('input[name="wizardKey"]').val()
    })
    ajaxUpdate(requestData, false, alertCallBack)
}

function disableSubmitButton() {
    try {
        $('#checkoutSubmit').prop('disabled', true)
        $('#checkoutSubmit').data('orig-value', $('#checkoutSubmit').val())
        $('#checkoutSubmit').val('Please Wait...')
    } catch (x) {

    }
}

function validateCheckoutForm() {
    $('input').removeClass('invalid')
    $('.validation').removeClass('passed failed')

    if ($('input.invalid').length) {
        $('.validation').addClass('failed')
    } else {
        $('.validation').addClass('passed')
    }

    if ($('#checkoutForm').length > 0) {
        // force re-validation on all form fields in case anything changed that Parsley didn't recognize
        $.each($('#checkoutForm').parsley().items, function(i, v) {
            if (typeof v.validatedOnce !== 'undefined' && v.validatedOnce) {
                v.validatedOnce = false
            }
        })
        // force revalidation of insurance options in case they are reloaded
        const $radios = $('#insurance-container input[type="radio"]')
        let insuranceOptionValidated = true
        if ($radios.is(':visible')) {
            insuranceOptionValidated = $radios.parsley('validate')
        }

        if (!$('#checkoutForm').parsley('validate') || !insuranceOptionValidated) {
            postPurchaseError('0001')
            resetSubmitButton()
            return false
        }
    }
    return true
}

function placeOrderWarningAndTotalChargesAreVisible() {
    // what in the actual f#@! does this condition mean? There is an && mixed with an || with no parenthesis
    // to specify the order of operations...
    // it basically says if something && something || something...
    // After testing what happens when you do:
    //      true && true || true
    //      true && true || false
    //      true && false || true
    //      true && false || false
    // I have come to the conclusion that the second OR-ed condition is safe to wrap in parentheses to disambiguate it.
    return $('#placeOrderWarning').is(':visible') &&
        (
            ($('#deliverySection').is(':visible') && $('#totalChargeSection').is(':visible')) ||
            ($('#feeRollup').is(':visible') && $('#totalChargeSectionRollup').is(':visible'))
        )
}

function shouldAbortPlaceOrder(shouldValidate, disableSubmit) {
    return shouldValidate === true && disableSubmit === true && (typeof disableScWarning === 'undefined' || disableScWarning === false) && !placeOrderWarningAndTotalChargesAreVisible() &&
        !$('#mmt-totalCharge').is(':visible')
}

function abortPlaceOrder() {
    const ticketId = $('#ticketId').val()
    const criticalElementsStatus = getSubmitCheckoutCriticalElementsStatus()
    const message = 'Place order disabled. Service Charge or Place Order Warning missing. Ticket Id=' + ticketId +
        '. User Agent:' + navigator.userAgent + '. Critical elements status: ' + criticalElementsStatus
    $.ajax({
        url: '/Logging.action',
        data: 'pid=' + $('input[name="productionId"]').val() + '&message=' + message
    })
    if (jQuery.browser.mobile) {
        alert('There was an internal system error while attempting to process your order.' +
            '\n\nYour order has not been placed.' +
            '\n\nPlease click on the link below to resume your order process, or call 866.848.8499 for further assistance.' +
            '\n\nWe apologize for any inconvenience this has caused.')
        if (typeof goToTicketList === 'function') {
            goToTicketList()
        } else {
            window.location.href = $('#ticket-page-url').attr('href')
        }
    } else {
        $('#disable-place-order-modal').modal('show')
    }
    resetSubmitButton()
}

function submitCheckoutform(shouldValidate, disableSubmit) {
    try {
        disableSubmitButton()
        if (shouldValidate === true) {
            if (!validateCheckoutForm()) {
                return
            }
        } else {
            // make sure parsley wasn't established elsewhere and prevent from validating the form when we don't want it to
            $('#checkoutForm').parsley('destroy')
        }

        if (shouldAbortPlaceOrder(shouldValidate, disableSubmit)) {
            abortPlaceOrder()
            return
        }

        // why is this in a setTimeout()? To cause Safari to visually disable the button and show the changed text before submitting the form.
        // otherwise, it would appear unchanged. The button would in fact actually be disabled though.
        setTimeout(function() {
            // eslint-disable-next-line eqeqeq
            if ($('#bt-nonce').length && $('#bt-nonce').val() == '') {
                $('#btOrderSubmit').click()
            } else {
                if ($('#existingShippingAddress').is(':checked')) {
                    $('#shouldValidateShipping').val(false)
                } else if ($('#newShippingAddress').is(':checked') && $('.address-validation-content:visible').length === 0) {
                    $('#shouldValidateShipping').val(true)
                }
                $('#checkoutForm').submit()
            }
        }, 0)

    } catch (e) {
        resetSubmitButton()
    }
}

function getSubmitCheckoutCriticalElementsStatus() {
    let finalStatus = ''
    const criticalElements = [
        'placeOrderWarning',
        'deliverySection',
        'totalChargeSection',
        'feeRollup',
        'totalChargeSectionRollup',
        'mmt-totalCharge'
    ]

    $.each(criticalElements, function(i, criticalElement) {
        let criticalElementStatus = criticalElement + ' = '
        const jqueryElement = $('#' + criticalElement)
        criticalElementStatus += ' exists: ' + (jqueryElement.length > 0)
        criticalElementStatus += ', visible: ' + jqueryElement.is(':visible')
        finalStatus += criticalElementStatus + '; '
    })

    return '(' + finalStatus + ')'
}

function showAjaxLoading() {
    $('#loadingWrapper').show()
    $('input').prop('disabled', true)
    $('select').prop('disabled', true)
    window.loadingDisplayTimestamp = new Date().getTime()
}

function hideAjaxLoading() {
    $('#loadingWrapper').hide()
    $('input').prop('disabled', false)
    $('select').prop('disabled', false)
    window.loadingDisplayTimestamp = 0
}

function resetPriceToZeroState(json) {
    $('#serviceTotalSection').hide()
    $('#serviceTotalSectionRollup').hide()
    $('#discountMessage').hide()
    $('#deliverySection').hide()
    $('#deliverySectionRollup').hide()
    $('#giftCardSection').hide()
    $('#giftCardSectionRollup').hide()
    $('#totalChargeSection').hide()
    $('#totalChargeSectionRollup').hide()
    $('#tfsChargeRollup').hide()
    displayShowAllInPricing(json)
}

function generateInsuranceFromHTML(insuranceOffer) {
    $('.insuranceOfferHtml').html(insuranceOffer.insuranceOfferHtml)
    $('.insuranceOfferHtml').attr('data-html', 'true')
    fixUpInsuranceOptions()
}

/**
 * Normalize our insurance options and apply a validator
 */
function fixUpInsuranceOptions() {
    const $radios = $('#insurance-container input[type="radio"]')
    // Add an isDecline prop to the data attributes on the last option
    // so we can more easily tell which option was selected for tracking
    $radios.last().data('isDecline', true)
    $radios.parsley('addConstraint', {
        required: true
    })
}

function shouldGenerateInsuranceFromHTML(insuranceOffer) {
    return insuranceOffer.insuranceOfferHtml && insuranceOffer.insuranceOfferHtml.length > 0
}

function shouldUpdateInsuranceTotals(insuranceOffer) {
    return insuranceOffer.totalPrice && insuranceOffer.totalPrice.length > 0
}

function updateInsuranceTotals(insuranceOffer) {
    $('#insuranceTotalPrice').html(insuranceOffer.totalPrice)
}

function handleInsuranceOfferJson(json) {
    // assign to global insuranceOffer var and local var
    const insuranceOffer = window.insuranceOffer = json.insuranceOffer || false

    if (insuranceOffer) {
        if (shouldGenerateInsuranceFromHTML(insuranceOffer)) {
            generateInsuranceFromHTML(insuranceOffer)
        } else if (shouldUpdateInsuranceTotals(insuranceOffer)) {
            updateInsuranceTotals(insuranceOffer)
        }
    }
    toggleInsurance()
}

function buildNoTixForm() {

    return $('<form></form>').attr({
        method: 'post',
        action: $('#checkoutForm').attr('action'),
        style: 'display:none;'
    })
        .append($('<input>').attr({
            type: 'hidden',
            name: 'notixRedirect',
            value: 'true'
        }))
        .append($('<input>').attr({
            type: 'hidden',
            name: 'affiliateRedirectURL',
            value: $('input[name="affiliateRedirectURL"]').val()
        }))
        .append($('<input>').attr({
            type: 'hidden',
            name: 'wizardKey',
            value: $('input[name="wizardKey"]').val()
        }))
        .append($('<input>').attr({
            type: 'hidden',
            name: 'productionId',
            value: $('input[name="productionId"]').val()
        }))

}

function handleIsShipping(json) {
    $('#shippingInfo').show()
    $('#chargeSection').show()
    $('#billingIsShippingLabel').show()

    if (typeof json.billingIsShipping !== 'undefined') {
        if (json.billingIsShipping === true) {
            $('.billingAddressFormAndSelectWrapper').hide()
        } else {
            $('.billingAddressFormAndSelectWrapper').show()
        }
        $('#billingIsShipping').prop('checked', json.billingIsShipping)
    }
}

function handleIsNotShipping() {
    $('#shippingInfo').hide()
    $('#billingIsShippingLabel').hide()
    $('.billingAddressFormAndSelectWrapper').show()
}

function handleIsDeliveryMethodSelectedJson(json) {
    if (json.deliveryMethodSelected === true) {
        $('#billingAddressSection').show()
        $('#deliveryInfoSection').show()
    } else {
        $('#billingAddressSection').hide()
        $('#deliveryInfoSection').hide()
    }
}

function handleShippingRequiredJson(json) {
    if (json.isShippingRequired === true) {
        $('#shippingAddressSection').show()
    } else {
        $('#shippingAddressSection').hide()
    }
}

function handleIsShippingJson(json) {
    if (json.isShipping === true) {
        handleIsShipping(json)
    } else {
        handleIsNotShipping()
    }
}

function handlePromoMessageJson(json) {
    if (typeof json.promoMessage !== 'undefined') {
        $('#promoMessage').html(json.promoMessage)
        $('#promoMessage').show()
    } else {
        $('#promoMessage').hide()
    }
}

function handleGiftCardMessageJson(json) {
    if (typeof json.giftCardMessage !== 'undefined') {
        $('#giftCardMessage').html(json.giftCardMessage)
        $('#giftCardMessage').show()
    } else {
        $('#giftCardMessage').hide()
    }
}

function handleChicagoTaxJson(json) {
    if (typeof json.chicagoTax !== 'undefined') {
        $('#chicagoTax').html(json.chicagoTax)
        $('#chicagoTaxSection').show()
    } else {
        $('#chicagoTaxSection').hide()
    }
}

function showPlaceOrderWarningIfNeeded(json) {
    if (typeof json.totalCharge !== 'undefined' && typeof json.deliveryDescription !== 'undefined') {
        $('#confirmTotal').html(json.totalCharge)
        $('#placeOrderWarning').show()
    } else {
        $('#placeOrderWarning').hide()
    }
}

function resetDeliveryMethodIfThereIsNoDescription(json) {
    if (typeof json.deliveryDescription === 'undefined') {
        $('#deliveryMethod').val('')
    }
}

function hideInsuranceAndBillingInfo() {
    // es6 global
    clearHostedFields()
    $('#bt-nonce').remove()
    window.paymentFieldsRemoved = true
    $('.paymentAndBilling').hide()

    // Resets payment option display in TFS checkout
    $('.paypalCheckout, .creditCardCheckout, .applePayCheckout').hide()
    $('#applePayCheckoutSubmit, #applePayPlaceOrderWarningText').hide()
    $('#checkoutSubmit, #defaultPlaceOrderWarningText').show()

    // Clear out any radio selections
    $('.paymentAndBilling input, .ticketInsurance input').prop('checked', false)
}

function showInsuranceAndBillingInfo() {
    $('.paymentAndBilling').show()

    // Adds payment nonce input and credit card fields back onto page if they were previously removed.
    if (window.paymentFieldsRemoved === true) {
        if ($('#bt-nonce').length === 0) {
            $('#checkoutForm').append('<input type="hidden" name="wizard.order.paymentNonce" id="bt-nonce" value=""/>')
        }
        // es6 global
        initNewCreditCardHostedFields()
    }
}

function giftCardCompletelyCoversCostOfTickets(json) {
    return typeof json.totalCharge !== 'undefined' && json.totalCharge === '$0.00'
}

function updateQuantity(json) {
    // updating this field too in case it different from the summary display for any reason
    // why would we do this before we possibly replace the conent inside the same selector?
    $('#quantitySelect').val(json.quantity)

    if (typeof json.htmlQuantityOptions !== 'undefined') {
        $('#quantitySelect').html(json.htmlQuantityOptions)
    }
}

function updateSeats(json) {
    if (typeof json.seats !== 'undefined') {
        $('#seatNumbers').html('Seats: <strong>' + json.seats + '</strong>').show()
    }
}

function updateUiFromJsonResponse(json) {
    updateQuantity(json)
    handleIsShippingJson(json)
    handleIsDeliveryMethodSelectedJson(json)
    handleShippingRequiredJson(json)
    handlePromoMessageJson(json)
    handleGiftCardMessageJson(json)
    handleChicagoTaxJson(json)
    showPlaceOrderWarningIfNeeded(json)
    resetDeliveryMethodIfThereIsNoDescription(json)
    if (giftCardCompletelyCoversCostOfTickets(json)) {
        hideInsuranceAndBillingInfo()
    } else {
        showInsuranceAndBillingInfo()
    }
    handleInsuranceOfferJson(json)
    updateTotals(json)
    updateSeats(json)

    $('#deliveryQuestion').text(json.deliveryQuestionTip)
    $('#deliveryQuestion-custom-infobox .custom_infobox_content_html_txt').html(json.deliveryQuestionDescription)

    if (typeof json.htmlDeliveryMethods !== 'undefined') {
        $('#deliveryMethod').html(json.htmlDeliveryMethods)
        if ($('#deliveryMethod').length && $('#deliveryMethod').val().length === 0) {
            resetPriceToZeroState(json)
        }
    }

    // If a user in TFS checkout has not selected a delivery method yet and changes the order
    // quantity, this will keep the gift card totals displayed (if there are any).
    updateGiftCardTotals(json)
}

function buildAjaxUpdateRequestData(requestData) {
    const formData = $('#checkoutForm').serializeArray()
    $.each(formData, function(i, field) {
        if (field.name !== 'ioBlackBox' && field.value.length > 0) {
            requestData.push(field)
        }
    })

    if (window.location.href.indexOf('legacyInsurance') > -1) {
        requestData.push({ name: 'legacyInsurance' })
    }
    return requestData
}

function handleWizardDoneJson() {
    const wizardDoneForm = $('<form></form>').attr({
        method: 'post',
        action: $('#checkoutForm').attr('action'),
        style: 'display:none;'
    })
        .append($('<input>').attr({
            type: 'hidden',
            name: 'completed',
            value: 'true'
        }))
        .append($('<input>').attr({
            type: 'hidden',
            name: 'wizardKey',
            value: $('input[name="wizardKey"]').val()
        }))
    $('body').append(wizardDoneForm)
    wizardDoneForm.submit()
}

function handleNoTixJson() {
    // why compose a new form from scratch with values from #checkoutForm and post it?
    // IE and FF don't correctly submit #checkoutForm with an appended hidden input field of name=notixRedirect, value=true.
    // The input is appended, but something breaks when the form is submitted to the server.
    const noTixForm = buildNoTixForm()
    $('body').append(noTixForm)
    noTixForm.submit()
}

function ajaxUpdate(requestData, isOnload, callback) {
    if (typeof $('input[name="wizardKey"]').val() === 'undefined') {
        return
    }

    try {
        requestData = buildAjaxUpdateRequestData(requestData)

        $.ajax({
            url: $('#checkoutForm').attr('action'),
            data: requestData,
            type: 'POST',
            dataType: 'json',
            beforeSend: function() {
                showAjaxLoading()
            },
            complete: function() {
                hideAjaxLoading()
            }
        })
            .done(function(json) {
                if (typeof json.wizardDone !== 'undefined' && json.wizardDone === true) {
                    handleWizardDoneJson()
                } else if (typeof json.notix !== 'undefined' && json.notix === true && typeof handleNoTickets !== 'undefined') {
                    // Presumably this is a function injected by partners so we redirect to their redirect url when
                    // there are no tickets available.
                    handleNoTickets()
                } else if (typeof json.notix !== 'undefined' && json.notix === true) {
                    handleNoTixJson()
                } else {
                    if (!isOnload) {
                        $('#hasAjaxUpdates').val('true')
                    }

                    updateUiFromJsonResponse(json)

                    if (typeof callback === 'function') {
                        callback()
                    }
                }
            })
            .fail(function(data) {
                if (data.status === 429) { // Too many requests
                    return
                }
                // reset wizard in the event of a failure.
                submitResetWizard()
            })
    } catch (e) {
        // reset wizard in absolute worst case error situation
        submitResetWizard()
    }
}

function shouldShowInsuranceCreditCard() {
    return isInsuranceOfferedCreditCard && (isCreditCardSelected || $('#creditCardCheckoutType').is(':checked'))
}

function shouldShowInsurancePaypal() {
    return isInsuranceOfferedPaypal && (isPaypalSelected || $('#paypalCheckoutType').is(':checked'))
}

function shouldShowInsuranceApplePay() {
    return isInsuranceOfferedApplePay && (isApplePaySelected || $('#applePayCheckoutType').is(':checked'))
}

function shouldShowInsurance() {
    return window.insuranceOffer && (shouldShowInsuranceCreditCard() || shouldShowInsurancePaypal() || shouldShowInsuranceApplePay())
}

function toggleInsurance() {
    $('.ticketInsurance').toggle(shouldShowInsurance())
}

function updateGiftCardTotals(json) {
    if (typeof json.giftCardTotal !== 'undefined') {
        $('#giftCardTotal').html(json.giftCardTotal)
        $('#giftCardTotalRollup').html(json.giftCardTotal)
        $('#giftCardSection').show()
        $('#giftCardSectionRollup').show()

        if (typeof json.serviceTotal !== 'undefined') {
            $('#serviceTotal').html(json.serviceTotal)
            $('#serviceTotalSection').show()
        }
    } else {
        $('#giftCardSection').hide()
        $('#giftCardSectionRollup').hide()
    }
}

function updateServiceFeeTotals(json) {
    if ($.cookie('showAllInPricing') === 'true' && json.isAipState === true && json.isAipStateWithDeliveryCharge === false) {
        $('#serviceTotalSection, #serviceTotalSectionRollup').hide()
    } else if (typeof json.serviceTotal !== 'undefined' && typeof json.deliveryDescription !== 'undefined') {
        $('#serviceTotal').html(json.serviceTotal)
        $('#serviceTotalRollup').html(json.serviceTotal)
        $('#serviceTotalSection, #serviceTotalSectionRollup').show()
    } else {
        $('#serviceTotalSection, #serviceTotalSectionRollup').hide()
    }
}

function updateTotals(json) {
    if (typeof json.discount !== 'undefined') {
        $('#discount').html(json.discount)
        $('#discountRollup').html(json.discount)
        $('#discountSection, #discountMessage').show()
        $('#discountSectionRollup, #discountMessageRollup').show()
    } else {
        $('#discountSection, #discountMessage').hide()
        $('#discountSectionRollup, #discountMessageRollup').hide()
    }

    if (typeof json.deliveryDescription !== 'undefined') {
        if (json.deliveryFee === '$0.00') {
            $('#deliveryFee, #deliveryFeeRollup').html('FREE')
            $('#deliveryDescription, #deliveryDescriptionRollup').html('Delivery Fee')
        } else {
            $('#deliveryFee, #deliveryFeeRollup').html(json.deliveryFee)
            $('#deliveryDescription, #deliveryDescriptionRollup').html(json.deliveryDescription)
        }
        $('#deliverySection, #deliverySectionRollup').show()
        displayShowAllInPricing(json)
        $('#totalChargeSection, #totalChargeSectionRollup').show()
    } else {
        $('#deliverySection, #deliverySectionRollup').hide()
        $('#totalChargeSection, #totalChargeSectionRollup').hide()
        displayShowAllInPricing(json)
    }

    updateServiceFeeTotals(json)
    updateGiftCardTotals(json)

    if (typeof json.feeTotal !== 'undefined') {
        $('#feeTotal').html(json.feeTotal)
        $('#feeTotalSection').show()
    } else {
        $('#feeTotalSection').hide()
    }

    if (typeof json.salesTax !== 'undefined' && json.deliveryMethodSelected === true) {
        $('#salesTaxSection').show()
    } else {
        $('#salesTaxSection').hide()
    }

    if (typeof json.serviceTotal !== 'undefined' && typeof json.deliveryDescription !== 'undefined') {
        $('#tfsTotalCharge').html(json.totalCharge)
        $('#tfsChargeRollup').show()
    } else {
        $('#tfsChargeRollup').hide()
    }
    if ($.cookie('showAllInPricing') === 'true' && json.isAipState === true && json.isAipStateWithDeliveryCharge === false) {
        $('#ticketTotalAIP').find('#allInPriceValue').html(json.allInPrice)
        $('#ticketTotalAIP').find('#aipServiceCharge').html('incl ' + json.serviceTotal + ' service fee')
    }
    $('#ticketTotal').html(json.ticketTotal)
    $('#ticketTotalSplit').html(json.ticketTotal)
    $('#ticketTotalCombined').html(json.ticketTotal)
    $('#totalCharge').html(json.totalCharge)
    $('#totalChargeRollup').html(json.totalCharge)
    $('#salesTaxTotal').html(json.salesTax)

    const totalCharge = json.totalChargeNumeric
    const giftCardTotal = json.giftCardTotalNumeric * -1
    const discountValue = json.discountNumeric * -1
    const salesTaxTotal = json.salesTaxNumeric
    const deliveryTotal = json.deliveryFeeNumeric
    const serviceChargeTotal = json.serviceTotalNumeric
    const subtotal = json.ticketTotalNumeric

    initBTPaypal(totalCharge);
    updateTotalForPayPal = totalCharge;

    if (typeof window.ApplePayInstance !== 'undefined') {
        if (subtotal) {
            const subtotalLineItem = {
                label: 'Subtotal',
                amount: subtotal
            }
            window.ApplePayInstance.setPaymentRequest(totalCharge, subtotalLineItem)
        }

        if (discountValue) {
            const discountLineItem = {
                label: 'Discount',
                amount: discountValue
            }
            window.ApplePayInstance.setPaymentRequest(totalCharge, discountLineItem)
        }

        if (serviceChargeTotal) {
            const serviceChargeLineItem = {
                label: 'Service Charge',
                amount: serviceChargeTotal
            }
            window.ApplePayInstance.setPaymentRequest(totalCharge, serviceChargeLineItem)
        }

        if (deliveryTotal) {
            const deliveryTotalLineItem = {
                label: json.deliveryDescription,
                amount: deliveryTotal
            }
            window.ApplePayInstance.setPaymentRequest(totalCharge, deliveryTotalLineItem)
        }

        if (giftCardTotal) {
            const giftcardLineItem = {
                label: 'Gift Card(s)',
                amount: giftCardTotal
            }
            window.ApplePayInstance.setPaymentRequest(totalCharge, giftcardLineItem)
        }

        if (salesTaxTotal) {
            const salesTaxTotalLineItem = {
                label: 'Sales Tax',
                amount: salesTaxTotal
            }
            window.ApplePayInstance.setPaymentRequest(totalCharge, salesTaxTotalLineItem)
        }

        if (json.applePayErrorMessage) {
            alert(json.applePayErrorMessage)
        }
    }
}

function storeCart(email, wizardKey, skipSignUp, trackingEvent) {
    if (!/^$/.test($('#email').val())) {
        if (($('#emailSignUp').is(':checked') || skipSignUp) && !window.hasStoredCart) {
            $.ajax({
                url: $('#checkoutForm').attr('action'),
                method: 'POST',
                data: 'insertCart=&wizardKey=' + wizardKey + '&emailAddress=' + encodeURIComponent(email)
            }).done(function(data) {
                window.hasStoredCart = true
            })
        }
    }
}

function submitResetWizard() {
    $('#wizardStepNumber').val('')
    submitCheckoutform(false, true)
}

function displayShowAllInPricing(json) {
    const showAipCookie = $.cookie('showAllInPricing')
    const deliveryMethod = $('#deliveryMethod').val()

    if (showAipCookie === 'true' && deliveryMethod !== '') {
        $('#chargeSection').show()
    } else if (showAipCookie === 'true' && json.isAipState === false && deliveryMethod === '') {
        $('#chargeSection').hide()
    }
}

/**
 * Handles all the GTM tracking calls for GA4
 * Just pass it a data object containing the event and any other data you want
 * to override in the global ga4TrackingData that was built when the page loaded.
 * @param data   { event: 'some_event_name', ...anyOtherData }
 */
function ga4TrackEvent(namespace, data, useOnlyPassedInData) {
    if (namespace === 'gtm') {
        let trackingData
        if (useOnlyPassedInData) {
            trackingData = $.extend(true, {}, data)
        } else {
            trackingData = $.extend(true, {}, window.ga4TrackingData, data)
        }
        debounce(function() {
            window.dataLayer.push(trackingData)
        }, 100, trackingData.event)

    }
}

function shouldTrackCheckoutLogin() {
    // For some reason we called the email verification field repeatedPassword.
    const $verifyEmailField = $('#repeatedPassword')
    const $emailField = $('#email')
    // if the email matches the verifyEmailField value OR there is no verify field but the email is valid
    return $emailField.parsley().validate() && ($emailField.val() === $verifyEmailField.val() || $verifyEmailField.length === 0)
}

function getJqueryEventNamespace(e) {
    // Parsley will step all over our jQuery events and remove the namespace, so if we don't have a namespace on our
    // event we need to go digging through the jQueery internals to find it. Fortunately jQuery's Event api provides
    // e.handleObj which stores details of the event handler from when it was originally attached.
    return e.namespace || e.handleObj.namespace
}

function generateEmailHash(e) {
    const hash = CryptoJS.SHA1(e.currentTarget.value.trim().toLowerCase())
    const hashedEmail = hash.toString(CryptoJS.enc.Base64)
    return hashedEmail
}

// Attaches all the ga4 tracking event handlers in 1 place so we don't need to modify existing handler code everywhere
function attachGa4TrackingEventHandlers() {
    // Set up ga4 tracking
    // Set up ga4 tracking to trigger on change events.
    // We namespace our events with "gtm" so we can react only to events that we triggered ourselves since partners
    // also trigger change events and we don't want to double track events triggered by event handlers that partners
    // might have attached to the same dom elements.
    const handlerName = 'change.gtm'
    $('#email, #repeatedPassword')
        .off(handlerName)
        .on(handlerName, function(e) {
            if (shouldTrackCheckoutLogin()) {
                const hashedEmail = generateEmailHash(e)
                // Only set the crm_id in a cookie if they already have one and it doesn't match the hashedEmail as they
                // must have changed email addresses. Otherwise it should only be saved in a cookie when they complete
                // their checkout.
                const CRM_ID = 'crm_id_2'
                const crmId = $.cookie(CRM_ID)
                if (crmId && crmId !== hashedEmail) {
                    $.cookie(CRM_ID, hashedEmail)
                    $.cookie('user_id', hashedEmail)
                    window.ga4TrackingData.user.is_returning_customer = window.ga4TrackingData.user.is_returning_customer || false
                }
                window.ga4TrackingData.user.crm_id = hashedEmail
                window.ga4TrackingData.user.user_id = hashedEmail
                // send only the user data
                const trackingData = $.extend(true, {}, {
                    event: 'checkout_login',
                    user: window.ga4TrackingData.user
                })
                // pass true as 3rd param to only use the data that is passed to the tracking call instead of extending
                // the global ga4TrackingData. We have to manually add the namespace here because parsley seems to strip
                // it off and we only track events with our namespace attached.
                ga4TrackEvent(getJqueryEventNamespace(e), trackingData, true)
            }
        })

    $('#deliveryMethod')
        .off(handlerName)
        .on(handlerName, function(e) {
            ga4TrackEvent(getJqueryEventNamespace(e), {
                event: 'add_shipping_info',
                ecommerce: {
                    shipping_tier: $(e.currentTarget).children(':selected').text().trim()
                }
            })
        })

    $('input[name="wizard.paymentMethodType"]')
        .off(handlerName)
        .on(handlerName, function(e) {
            ga4TrackEvent(getJqueryEventNamespace(e), {
                event: 'add_payment_info',
                ecommerce: {
                    payment_type: e.currentTarget.value
                }
            })
            toggleInsurance()
        })

    // To attach to dom elements that might not be present on page render we need to capture the change event
    // on the container and filter for the dom elements we want.
    $('#insurance-container')
        .off(handlerName, 'input[type=radio][name=insuranceOption]')
        .on(handlerName, 'input[type=radio][name=insuranceOption]', function(e) {
            ga4TrackEvent(getJqueryEventNamespace(e), {
                event: 'select_insurance',
                ecommerce: {
                    insurance_attached: !$(e.currentTarget).data('isDecline'),
                    insurance_template: insuranceOffer.templateId
                }
            })
        })

    $('#quantitySelect').on(handlerName, function(e) {
        // Set the quantity in our gaTrackingData so we will send the new
        // quantity in any tracking calls that send the items.
        window.ga4TrackingData.ecommerce.items[0].quantity = e.currentTarget.value
    })

    $('#checkoutSubmit')
        .off('click.gtm')
        .on('click.gtm', function(e) {
            ga4TrackEvent(getJqueryEventNamespace(e), {
                event: 'purchase_authorization'
            })
        })

    $('#gatracking-purchase-error')
        .off(handlerName)
        .on(handlerName, function(e) {
            postPurchaseError($('#gatracking-purchase-error').text())
        })
}

function postPurchaseError(errorCode = '') {
    ga4TrackEvent('gtm', {
        event: 'purchase_error',
        error_code: errorCode
    })
}

function debounce(func, within = 300, timerId = null) {
    window.callOnceTimers = window.callOnceTimers || {}
    if (timerId == null) {
        timerId = func
    }
    let timer = window.callOnceTimers[timerId]
    clearTimeout(timer)
    timer = setTimeout(() => func(), within)
    window.callOnceTimers[timerId] = timer
}

function initValidation() {
    fixUpInsuranceOptions()

    // This doesn't need to be wrapped in shouldUseParsley anymore as that only applied to ie < 8. OMG!
    $('#checkoutForm').parsley({
        listeners: {
            onFieldValidate: function(elem) {
                // if field is not visible, do not apply Parsley validation!
                if (!$(elem).is(':visible') || $(elem).hasClass('doNotValidate')) {
                    return true
                }
                return false
            }
        },
        validators: {
            validatenamelength: function() {
                return {
                    validate: function(val, maxlength) {
                        const $shippingAddressFirstName = $('#shippingAddressFirstName')
                        const $shippingAddressLastName = $('#shippingAddressLastName')

                        if ($shippingAddressFirstName.length > 0 && $shippingAddressLastName.length > 0) {
                            return ($shippingAddressFirstName.val().length + $shippingAddressLastName.val().length) < maxlength
                        } else {
                            return true
                        }
                    },
                    priority: 2
                }
            }
        },
        messages: {
            validatenamelength: 'Full name cannot be more than 35 characters.'
        }
    })
}

function initUi() {

    const changeEvtName = 'change.tfs'
    const clickEvtName = 'click.tfs'

    // Expand #promo-code accordion if user submits a code
    if ($('#promoCode').length > 0 && $('#promoCode').val().length > 1) {
        $('#collapse-promo-code').collapse('show')
        $('#promo-code .accordion-toggle').removeClass('.collapsed')
    }

    // Show server side errors if there are any
    if ($('.error-container').children().length > 0) {
        $('.error-container').parent().show()
        $('#gatracking-purchase-error').trigger('change.gtm')
    }

    $('#shippingAddressCountry')
        .off(changeEvtName)
        .on(changeEvtName, function() {
            const deliveryMethodValue = parseInt($('#deliveryMethod').val(), 10)
            // if delivery is not instant or email delivery and the country changes, do not display the price change alert
            const instantDownloadOrEmail = (deliveryMethodValue === window.INSTANT_DOWNLOAD || deliveryMethodValue === window.EMAIL_DELIVERY)
            if (!instantDownloadOrEmail) {
                handlePriceAlert()
            }
        })

    $('#billingAddressCountry, #deliveryMethod, #billingAddressState')
        .off(changeEvtName)
        .on(changeEvtName, function() {
            handlePriceAlert()
        })

    $('#deliveryMethodAlert .close').on(clickEvtName, function() {
        $('#deliveryMethodAlert').hide()
    })

    let insuranceSelectionId
    $('#insurance-container').off(changeEvtName).on(changeEvtName, function(event) {
        const allianzSelectionId = event.target.defaultValue
        if (event.target.id === 'insuranceOption0' && allianzSelectionId !== insuranceSelectionId) {
            ajaxUpdate([{
                name: 'updateCheckout',
                value: true
            }, {
                name: 'wizardKey',
                value: $('input[name="wizardKey"]').val()
            }], false, function() {
                const $insuranceOption0 = $('#insurance-container #insuranceOption0')
                if (!$insuranceOption0.attr('checked')) {
                    $insuranceOption0.prop('checked', true)
                }
            })
        }
        insuranceSelectionId = allianzSelectionId
    })

    $('#quantitySelect').on(changeEvtName, function() {
        const requestData = []
        requestData.push({
            name: 'updateQuantity',
            value: 'true'
        })
        requestData.push({
            name: 'wizard.quantity',
            value: $('#quantitySelect').val()
        })

        ajaxUpdate(requestData, false)
    })

    $('#applyPromo')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            const requestData = []
            requestData.push({
                name: 'applyPromo',
                value: 'true'
            })
            requestData.push({
                name: 'promoCode',
                value: $('#promoCode').val()
            })
            ajaxUpdate(requestData, false)
        })

    $('#applyGiftCardCode')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            const requestData = []
            requestData.push({
                name: 'applyGiftCard',
                value: 'true'
            })
            requestData.push({
                name: 'giftCardCode',
                value: $('#giftCardCode').val()
            })
            ajaxUpdate(requestData, false)
        })

    $('#removeGiftCards')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            const requestData = []
            requestData.push({
                name: 'removeGiftCards',
                value: 'true'
            })
            ajaxUpdate(requestData, false)
        })

    $('#paypalButton')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('#checkoutForm').append('<input type="hidden" name="verifyWithPaypal" />')
            submitCheckoutform(false, false)
        })

    $('.orderSummary .preferred.collapsed a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .preferred.collapsed').hide()
            $('.orderSummary .preferred.expanded').show()
        })

    $('.orderSummary .preferred.expanded a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .preferred.collapsed').show()
            $('.orderSummary .preferred.expanded').hide()
        })

    $('.orderSummary .eticket.collapsed a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .eticket.collapsed').hide()
            $('.orderSummary .eticket.expanded').show()
        })

    $('.orderSummary .eticket.expanded a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .eticket.collapsed').show()
            $('.orderSummary .eticket.expanded').hide()
        })

    $('.orderSummary .instantdl.collapsed a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .instantdl.collapsed').hide()
            $('.orderSummary .instantdl.expanded').show()
        })

    $('.orderSummary .instantdl.expanded a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .instantdl.collapsed').show()
            $('.orderSummary .instantdl.expanded').hide()
        })

    $('.orderSummary .zoned.collapsed a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .zoned.collapsed').hide()
            $('.orderSummary .zoned.expanded').show()
        })

    $('.orderSummary .zoned.expanded a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .zoned.collapsed').show()
            $('.orderSummary .zoned.expanded').hide()
        })

    $('.orderSummary .guarantee.collapsed a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .guarantee.collapsed').hide()
            $('.orderSummary .guarantee.expanded').show()
        })

    $('.orderSummary .guarantee.expanded a')
        .off(clickEvtName)
        .on(clickEvtName, function() {
            $('.orderSummary .guarantee.collapsed').show()
            $('.orderSummary .guarantee.expanded').hide()
        })

    // social
    let twitterloaded = false
    $('#twitter-tab')
        .off(clickEvtName)
        .on(clickEvtName, function(e) {
            if (!twitterloaded) {
                (function(d, s, id) {
                    let js
                    const fjs = d.getElementsByTagName(s)[0]
                    if (!d.getElementById(id)) {
                        js = d.createElement(s)
                        js.id = id
                        js.src = '//platform.twitter.com/widgets.js'
                        fjs.parentNode.insertBefore(js, fjs)
                    }
                }(document, 'script', 'twitter-wjs'))
                twitterloaded = true
            }
        })

    let gplusloaded = false
    $('#gplus-tab')
        .off(clickEvtName)
        .on(clickEvtName, function(e) {
            if (!gplusloaded) {
                (function() {
                    const po = document.createElement('script')
                    po.type = 'text/javascript'
                    po.async = true
                    po.src = 'https://apis.google.com/js/plusone.js'
                    const s = document.getElementsByTagName('script')[0]
                    s.parentNode.insertBefore(po, s)
                })()
                gplusloaded = true
            }
        })

    $('#tabs')
        .off('hover.tfs')
        .on('hover.tfs', function(e) {
            const at = document.createElement('script')
            at.type = 'text/javascript'
            at.async = true
            at.src = 'https://s7.addthis.com/js/250/addthis_widget.js#pubid='
            const s = document.getElementsByTagName('script')[0]
            s.parentNode.insertBefore(at, s)
        })
}

// domready event handler
$(function() {
    // in common.js
    if (isIos()) {
        $('html').addClass('iOS')
    }

    attachGa4TrackingEventHandlers()
    // When shipping method is preselected we need to force the onchange event to trigger so it sends a tracking call.
    // This must happen AFTER we attachGa4TrackingEventHandlers
    if (window.hasPreselectedShippingId) {
        $('#deliveryMethod').trigger('change.gtm')
    }

    // user could have hit the back button and then the forward button
    // check to see if the browser remembered their selections and refresh the form from the wizard
    if ($('#hasAjaxUpdates').val() === 'true') {
        const requestData = []
        // just call updateDelivery for now. all it does is initialize the delivery options and generate
        // the checkout update json structure. If we add anything to that method, we'll have to write a new
        // method for this operation.
        requestData.push({
            eventType: 'add_shipping_info',
            name: 'updateDelivery',
            value: 'true'
        })
        ajaxUpdate(requestData, true)
    }

    initValidation()
    initUi()

    /* Set up jQuery Payment plugin */
    $('[data-numeric]').payment('restrictNumeric')
    $('.cc-number').payment('formatCardNumber')
    $('.cc-cvc').payment('formatCardCVC')
})
