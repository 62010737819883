var utils = function(){

	//Private Vars
	var TEMPLATE_DIR = '/res/scripts/templates/';
	var TEMPLATE_SUFFIX	= '.tpl.htm';
	var templateCache = {};


	// IIFE Immediately-Invoked Function Expression
	var initFcn = function(){
	}();

	// Private Fcns
	function alpha() {

	}


	/*
	* Public API
	*/
	var api = {}, _getAllQueryParameters;

	api.info = 'utils.js API: Test in Console with; utils.info,   utils.getTemplate() etc. ';

	api.getTemplateById = function(path, id){
		var templates = api.getTemplate(path);
		var template = $(templates).filter(id).html();
		return template;
	}

	/*
	 * Get Templates
	 *  TODO: lookinto using localStorage.  e.g. localStorage.setIiem(name, value), localStorage.getItem(name);
	 */
	api.getTemplate = function(path, name){
		var url;
		if(typeof name === "undefined"){
			url = path;
		}else {
			url = 	path + name + TEMPLATE_SUFFIX;
		}

		var data = "<h4>Failed to load template:" + url + "</hr>";
		var cacheName = path+ ":" + name;
		var templateHtml = "";

		if (  (templateHtml =  templateCache[cacheName])) {
			return templateHtml;
		}else {
			$.ajax({
				dataType: 'text',
				method: 'GET',
				async: false,
				url: url,
				success: function(response){
					data = response;
					templateCache[cacheName] = data;
				},
				error: function(jqXHR, textStatus, errorThrown){
					var errorStr = "Error getting Template:" + url + "; Text:" +textStatus + "; Error:" + errorThrown;
					data = "<p>" + errorStr + "</p>";
				}
			});
			return data;
		};
	}


	api.prependHashIfNeeded = function(id){
			if( ! /^#/.test(id)) {
				id = id.replace(/(.*)/, "#$1");
			}
			return id;

		};


	/**
	 * Simple localStorage with Cookie Fallback
	 *
	 * USAGE:
	 * ----------------------------------------
	 * Set New / Modify:
	 *   store('my_key', 'some_value');
	 *
	 * Retrieve:
	 *   store('my_key');
	 *
	 * Delete / Remove:
	 *   store('my_key', null);
	 */

	api.store = function(key, value) {

		var lsSupport = false;

		// Check for native support
        try {
            window.localStorage.setItem('ls_test', 123);
            window.localStorage.removeItem('ls_test');
            lsSupport = true;
        } catch(e) {
            lsSupport = false;
        }

		// If value is detected, set new or modify store
		if (typeof value !== "undefined" && value !== null) {
			// Convert object values to JSON
			if ( typeof value === 'object' ) {
				value = JSON.stringify(value);
			}
			// Set the store
			if (lsSupport) { // Native support
				window.localStorage.setItem(key, value);
			} else { // Use Cookie
				createCookie(key, value, 30);
			}
		}

		// No value supplied, return value
		if (typeof value === "undefined") {
			// Get value
			if (lsSupport) { // Native support
				data = window.localStorage.getItem(key);
			} else { // Use cookie
				data = readCookie(key);
			}

			// Try to parse JSON...
			try {
				data = JSON.parse(data);
			}
			catch(e) {
				console.error('Error parsing data from localStorage: ', e);
			}

			return data;

		}

		// Null specified, remove store
		if (value === null) {
			if (lsSupport) { // Native support
				window.localStorage.removeItem(key);
			} else { // Use cookie
				createCookie(key, '', -1);
			}
		}

		/**
		 * Creates new cookie or removes cookie with negative expiration
		 * @param  key       The key or identifier for the store
		 * @param  value     Contents of the store
		 * @param  exp       Expiration - creation defaults to 30 days
		 */

		function createCookie(key, value, exp) {
			var date = new Date();
			date.setTime(date.getTime() + (exp * 24 * 60 * 60 * 1000));
			var expires = "; expires=" + date.toGMTString();
			document.cookie = key + "=" + value + expires + "; path=/";
		}

		/**
		 * Returns contents of cookie
		 * @param  key       The key or identifier for the store
		 */

		function readCookie(key) {
			var nameEQ = key + "=";
			var ca = document.cookie.split(';');
			for (var i = 0, max = ca.length; i < max; i++) {
				var c = ca[i];
				while (c.charAt(0) === ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		}

	};

	api.cookie = {
		getItem: function (sKey) {
			if (!sKey) { return null; }
			return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
		},
		setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
			if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
			var sExpires = "";
			if (vEnd) {
				switch (vEnd.constructor) {
					case Number:
						sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
						break;
					case String:
						sExpires = "; expires=" + vEnd;
						break;
					case Date:
						sExpires = "; expires=" + vEnd.toUTCString();
						break;
				}
			}
			document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
			return true;
		},
		removeItem: function (sKey, sPath, sDomain) {
			if (!this.hasItem(sKey)) { return false; }
			document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
			return true;
		},
		hasItem: function (sKey) {
			if (!sKey) { return false; }
			return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
		},
		keys: function () {
			var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
			for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
			return aKeys;
		}
	};

	api.buildParamStringFromObject = function(paramObject, includeIfNull) {

		var params = "";
		var isFirstParam = true;

		for (var i in paramObject) {
			if (paramObject.hasOwnProperty(i)) {
				if (paramObject[i] !== null || includeIfNull) {

					if (isFirstParam) {
						params = "?" + i + "=" + paramObject[i];
						isFirstParam = false;
					} else {
						params += "&" + i + "=" + paramObject[i];
					}

				}
			}
		}

		return params;

	};

	// Self-propagating extend function that classes can use.
	api.extend = function(protoProps, staticProps) {
		var parent = this;
		var child;

		// Use the parent constructor by default.
		if (protoProps && _.has(protoProps, 'constructor')) {
			child = protoProps.constructor;
		} else {
			child = function(){ return parent.apply(this, arguments); };
		}

		// Add static properties to the constructor function, if supplied.
		_.extend(child, parent, staticProps);

		// Set the prototype chain to inherit from parent, without calling
		// parent's constructor function and add the prototype properties.
		child.prototype = _.create(parent.prototype, protoProps);
		child.prototype.constructor = child;

		// Store a reference to parent's prototype in case it's needed.
		child.__super__ = parent.prototype;

		return child;
	};

	api.getParameterByName = function(name) {
		var match = new RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	};

	api.updateParameterByName = function(name, newValue, addMissingParam) {
		var paramString = "";

		if (typeof _ !== 'undefined') {
			var queryparams = _getAllQueryParameters();

			if (addMissingParam && !_.has(queryparams, name)) {
				queryparams[name] = newValue;
			} else {
				queryparams = _.mapObject(queryparams, function(val, key) {
					if (key === name) {
						return val = newValue;
					} else {
						return val;
					}
				});
			}

			!_.isEmpty(queryparams) && (paramString = '?' + $.param(queryparams));
		}

		window.location = window.location.pathname + paramString;
	};

	api.removeParameterByName = function(name) {
		var paramString = "";

		if (typeof _ !== 'undefined') {
			var queryparams = _.omit(_getAllQueryParameters(), name);

			!_.isEmpty(queryparams) && (paramString = '?' + $.param(queryparams));
		}

		window.location = window.location.pathname + paramString;
	};

	api.sendInHouseLog = function(message) {		
        var parsedMessage = (_.isString(message)) ? message : JSON.stringify(message);

        $.ajax({url: "/Logging.action?message=" + parsedMessage});
	};

	api.sendInHouseLogFromError = function(error, messageObject, errorHandlerSource) {
		messageObject = messageObject || {};
		errorHandlerSource = errorHandlerSource || "";
        try {
            var errorMessage = messageObject.message ? messageObject.message : error.message;

            if (errorMessage.indexOf('Script error.') > -1) {
                return;
            }

            StackTrace.fromError(error)
                ["then"](function(stackframes) {
                    var stringifiedStack = stackframes.map(function(sf) {
                        return JSON.stringify(sf);
                    }).join(' - ');
                    api.sendInHouseLog("step4 checkout page (" + errorHandlerSource + "):" + errorMessage + " from: " + stringifiedStack);
                })
                ["catch"](function(e) {
                    var stringifiedErrorMessage;
                    if(_.isEmpty(messageObject)) {
                        var stack = (error.error && error.error.stack) ? error.error.stack : "";
                        stringifiedErrorMessage = [
                            'Message: ' +  errorMessage,
                            'Stack: ' + stack,
                            'Error:' + JSON.stringify(error)
                        ].join(' - ');
                    } else {
                        stringifiedErrorMessage = [
                            'Message: ' + messageObject.message,
                            'URL: ' + messageObject.url,
                            'Line: ' + messageObject.lineNumber,
                            'Column: ' + messageObject.columnNumber,
                            'Error object: ' + JSON.stringify(error)
                        ].join(' - ');
                    }
                    api.sendInHouseLog("step4 checkout page (" + errorHandlerSource + "): " + stringifiedErrorMessage);
                });

        } catch (e) {}
    };


	api.hasViewedConfirmationPage = function() {

		try {
			var hasViewedConfirmationPage = false;
			var wizardKey = api.getParameterByName("wizardKey");
			var wizardCookie = api.cookie.getItem("__hvcpo");
			var wizardsInCookie = [];

			if (!wizardKey) {
				return false;
			}

			if (wizardCookie) {

				wizardsInCookie = wizardCookie.split(",");

				if (wizardsInCookie.indexOf(wizardKey) !== -1) {
					hasViewedConfirmationPage = true;
				} else {

					hasViewedConfirmationPage = false;
					wizardsInCookie.push(wizardKey);

					// only keep 10 wizard keys in the cookie so we don't overflow.
					if (wizardsInCookie.length > 10) {
						wizardsInCookie.shift();
					}

					api.cookie.setItem('__hvcpo', wizardsInCookie.join(","));

				}

			} else {
				hasViewedConfirmationPage = false;
				api.cookie.setItem("__hvcpo", wizardKey)
			}

			return hasViewedConfirmationPage;
		} catch(e) {
			api.sendInHouseLog("hasViewedConfirmationPage error: " + e);
			return false;
		}

	};

    /**
     * Store location attributes in localStorage
     * Similar to html5 history up to the last 2 pages visited
     *
     */
    api.storeReferrers = function() {
        var referrerArr = api.store('referrer') || [],
		    locationObj = window.location,
		    essentialLocationObj = {
                origin: locationObj.origin,
                pathname: locationObj.pathname,
                href: locationObj.href
            };

        // prevent localstorage from getting oversized
        (referrerArr.length > 2) && referrerArr.pop();

        if (referrerArr.length && (referrerArr[0].pathname !== essentialLocationObj.pathname)) {
            referrerArr.unshift(essentialLocationObj);
            api.store('referrer', referrerArr);
        } else if (!referrerArr.length) {
            api.store('referrer', [essentialLocationObj]);
        }
    };

    api.getIosVersion = function() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
    };

    api.isIosVersionApplePayCompatible = function(iosVersion) {
    	if (!iosVersion) {
			return false;
        }

    	var majorVersion = iosVersion[0];
    	var minorVersion = iosVersion[1];
        return (majorVersion >= 10 && !(majorVersion === 10 && minorVersion < 1));
    };


    /*
    * End Public API
    */

	/*
	 * Alternative Syntax to return the API with an anonmous object

		return {
		  getTemplate : function(){ return templateStr }),
		  getName: function(){ return name}),
			...

		}

	 */

	_getAllQueryParameters = function() {
		var queryparameters = {};

		if (typeof _ !== 'undefined') {
			queryparameters = _.chain(location.search.slice(1).split('&')).map(function(item) {
				if (item) {
					return item.split('=');
				}
			}).compact().object().value();
		}

		return queryparameters;
	};

	return api;

}();
