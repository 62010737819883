;/*
*
*  Live Chat Link Plugin
*  Enables adding live chat links to sites.
*  Usage:
*  		1.  Add a link with a class "open-livechat" and your livechatid id which enables opening of a modal to the correct live chat form.
*           Example:
*
*           <a href="<yourlivechatlink>" class="open-livechat">Live Chat!</a>
*
*       2.  Call from Javascript using openLiveChatModal(<yourlivechatlink>);  This may have issues with popup blockers, so option #1 is advised.
*           Example:
*
*           <script>
*               window.openLiveChatModal("xyz/account/Chat.action?wsuser=123");
*           </script>
*
*  Browser compatibility: desktop IE8+;  all modern mobile browsers.
*  Dependencies: None.
*
* */

(function(window) {

	var document = window.document,
		root = document.documentElement,
		modern = document.addEventListener,
		add = modern ? 'addEventListener' : 'attachEvent',
		remove = modern ? 'removeEventListener' : 'detachEvent',
		prefix = modern ? '' : 'on';

	var livechatPopup = null;

	// bind click event listeners to live chat elements.
	onload(function() {

		var livechatLinks = document.getElementsByClassName ? document.getElementsByClassName("open-livechat") : getElementsByClassNameFallback("open-livechat");

		for (var i = 0; i < livechatLinks.length; i++) {
			livechatLinks[i][add](prefix + 'click', onLiveChatClick, false);
		}

		function onLiveChatClick(e) {

			if (e.preventDefault) {
				e.preventDefault();
			} else {
				e.returnValue = false;
			}

			openModal(e.currentTarget.getAttribute("href"));
		}

	});

	// Expose openLiveChatModal to the window unless something has taken it.
	if (typeof window.openLiveChatModal === "undefined") {
		window.openLiveChatModal = openModal;
	}

	function openModal(href) {

		if(!livechatPopup || livechatPopup.closed) {
			livechatPopup = window.open(href,'_blank','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=700,top=0,left=0');
			return false;
		}
		else {
			livechatPopup.focus();
		}
	}

	function onload(fn) {

		var done = false, top = true;

		var init = function(e) {
			if (e.type == 'readystatechange' && document.readyState != 'complete') return;
			(e.type == 'load' ? window : document)[remove](prefix + e.type, init, false);
			if (!done && (done = true)) fn.call(window, e.type || e);
		};

		var poll = function() {
			try { root.doScroll('left'); } catch(e) { setTimeout(poll, 50); return; }
			init('poll');
		};

		if (document.readyState == 'complete') fn.call(window, 'lazy');
		else {
			if (!modern && root.doScroll) {
				try { top = !window.frameElement; } catch(e) { }
				if (top) poll();
			}
			document[add](prefix + 'DOMContentLoaded', init, false);
			document[add](prefix + 'readystatechange', init, false);
			window[add](prefix + 'load', init, false);
		}

	}

	function getElementsByClassNameFallback(className, tag, elm) {
		tag = tag || "*";
		elm = elm || document;
		var classes = className.split(" "),
			classesToCheck = [],
			elements = (tag === "*" && elm.all)? elm.all : elm.getElementsByTagName(tag),
			current,
			returnElements = [],
			match;
		for(var k=0, kl=classes.length; k<kl; k+=1){
			classesToCheck.push(new RegExp("(^|\\s)" + classes[k] + "(\\s|$)"));
		}
		for(var l=0, ll=elements.length; l<ll; l+=1){
			current = elements[l];
			match = false;
			for(var m=0, ml=classesToCheck.length; m<ml; m+=1){
				match = classesToCheck[m].test(current.className);
				if (!match) {
					break;
				}
			}
			if (match) {
				returnElements.push(current);
			}
		}
		return returnElements;
	};

})(window);



