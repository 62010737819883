/*! infobox 0.9
 *
 *  Library Module To Support Custom Chat Use Cases
 *
 *   8/1/2013 -Still refining got to work with botth
 *    gift_center, and production statium charts.
 *    TODO will just have it take on an option {}
 *    configuration object.
 */
var infobox = function() {

    var UNDEF = "undefined",
            debug = true,
            OBJECT = "object",
            TEMPLATE_DIR = "/res/scripts/widgets/infobox/",
            TEMPLATE_SUFFIX = ".tpl.htm",
            win = window,
            doc = document,
            nav = navigator,
            activeInfoboxes = [], // need to persist for window re-size. Just Options object which represents the Infobox!
            hoverInfoboxContainer = false,
            hoverMiniProfileContainer = false;
    constants = {
        placementLocation: {
            TOP_LEFT: 1,
            TOP: 2,
            TOP_RIGHT: 3,
            RIGHT: 4,
            BOTTOM_RIGHT: 5,
            BOTTOM: 6,
            BOTTOM_LEFT: 7,
            LEFT: 8,
            AUTO: 'auto'
        },
        triggerEvent: {
            HOVER: "hover",
            CLICK: "click",
            MANUAL: "manual"
        },
        pointerType: {
            DEFAULT: "default",
            NONE: "none"
        }

    };

    /*
     *  IIFE  to Init Module
     */
    initCustom = function() {
        init();
    }();

    /*
     * Local Functions
     */

    function init() {
        // NA for Now
    }


    function buildRenderObject(options) {
        renderObject = {};
        renderObject.id = options.targetSelector.replace(/^#/, "");
        renderObject.customClasses = options.customClasses;
        renderObject.showCloseButton = options.showCloseButton;
        renderObject.data_txt = options.data_txt;
        renderObject.html_txt = options.html_txt;
        if (typeof options.htmlSelector != 'undefined') {
            renderObject.html_txt = $(options.htmlSelector).html()
        }
        return renderObject;
    }

    /*****************************************
     *
     * Wrappers to simplify Infobox Renders
     *  -UX stuff
     *
     *****************************************/
    function makeInfobox(targetSelector, htmlFile, htmlId, placementLocation, triggerEvent, width, height) {

        // Basically build build the Options off a Base Object.
        // Handle the --getting the Injected template stuff baby

        // Handle Defaults -- Shorthand baby!
        // Add more later man!
        placementLocation = placementLocation || infobox.constants.placementLocation.AUTO;
        triggerEvent = triggerEvent || infobox.constants.triggerEvent.CLICK;

        // Pull from Example Buddy!

        var exampleOptions = {
            triggerEvent: triggerEvent,
            targetSelector: targetSelector,
            placementLocation: placementLocation,
            offsetAdjustment: {"x": 0, "y": 0},
            pointerType: infobox.constants.pointerType.DEFAULT,
            templateName: "template",
            customClasses: 'selected left',
            showCloseButton: true,
            activeWhenOverInfobox: true,
            html_txt: utils.getTemplateById(htmlFile, htmlId),
            width: width,
            height: height
        };

        try {
            render(exampleOptions);
        } catch (err) {
            if (debug) {
                console.log("Error in makeInfobox() call to render() : " + err);
            }

        }

    }

    /*****************************************
     *
     * BASE RENDER
     *
     *****************************************/
    function render(options) {

        options.hover = false;
        options.visible = false;

        if (getIEVersion() > 8 || getIEVersion() == -1) {
            activeInfoboxes.push(options);
        }

        var target = options.targetSelector;
        if ($(target + "-custom-infobox").length === 0) {
            var infoboxHtml = utils.getTemplate(TEMPLATE_DIR, options.templateName);
            var template = Handlebars.compile(infoboxHtml);
            var templateRenderResult = template(buildRenderObject(options));
            $("body").append(templateRenderResult);
        }

        $(target + "-custom-infobox").show(); // TODO : Need to show infobox now we can get the size info to place correct. do workaround!
        var adjustedPoints = adjustInfoboxPosition(options);
        if (options.triggerEvent !== infobox.constants.triggerEvent.MANUAL) {
            // to support stadium chart for now! TODO move to attachInfoboxEvents maybe!
            $(target + "-custom-infobox").hide();
        }
        $(target + "-custom-infobox").css({
            'left': adjustedPoints.x,
            'top': adjustedPoints.y
        });
        attachInfoboxEvents(options);

        // Extra changes requested.
        if (typeof options.height != 'undefined') {
            $(target + "-custom-infobox .custom_infobox_content").css("height", options.height);
        }
        if (typeof options.width != 'undefined') {
            $(target + "-custom-infobox .custom_infobox_content").css("width", options.width);
        }

        // To fix issue to get sizes to be correct.  Some browser bug.
        // But Breaks the Chart Manaual example -- hides it..
        if (options.triggerEvent !== constants.triggerEvent.MANUAL) {
            theResize();
        }

    }

    function adjustInfoboxPosition(options) {

        // Offsets based on CSS Updates for Nib.
        var horizOffset = 10;

        var target = options.targetSelector;
        //var targetOffset = $(target).offset();

        // Note, jquery not including the padding, and border, thus manually add it!
        var infoboxPadding = parseInt($(target + '-custom-infobox  ').css('padding-left'));
        var infoboxBorder = parseInt($(target + '-custom-infobox  ').css('border-left-width'));
        var infoboxWidth = parseInt($(target + '-custom-infobox  ').css('width'));
        infoboxWidth = infoboxWidth + 2 * infoboxPadding + 2 * infoboxBorder;
        var infoboxHeight = parseInt($(target + '-custom-infobox ').css('height'));
        infoboxHeight = infoboxHeight + 2 * infoboxPadding + 2 * infoboxBorder;
        var containerWidth = parseInt($(target).css('width'));
        var containerHeight = parseInt($(target).css('height'));
        var yDelta = 0;
        var xDelta = 0;
        // maybe convert quadrants..
        var container = target + '-custom-infobox.custom_infobox_container';


        // the initial 8, there are now 12!
        var adjPlacement = options.placementLocation;
        if (adjPlacement === constants.placementLocation.AUTO) {
            adjPlacement = handleSmartPlacement(target);
        }

        var adjustedPoints = {
            x: 0 + options.offsetAdjustment.x,
            y: 0 + options.offsetAdjustment.y
        };

        if (adjPlacement === constants.placementLocation.BOTTOM_RIGHT) {
            // quadrant 1
            $(container + " .custom-nib").addClass("infobox-nib-top-left");
            yDeltaCalc = function() {
                return (triangleHeight + containerHeight);
            };
            xDeltaCalc = function() {
                return (-(0.5 * (triangleWidth) + horizOffset) + containerWidth);
            };

        } else if (adjPlacement === constants.placementLocation.BOTTOM_LEFT) {
            // quadrant 2
            $(container + " .custom-nib").addClass("infobox-nib-top-right");
            yDeltaCalc = function() {
                return (triangleHeight + containerHeight);
            };
            xDeltaCalc = function() {
                return(-(infoboxWidth - 0.5 * (triangleWidth) - horizOffset));
            };

        } else if (adjPlacement === constants.placementLocation.TOP_LEFT) {
            // quadrant 3
            $(container + " .custom-nib").addClass("infobox-nib-bottom-right");
            yDeltaCalc = function() {
                return(-(triangleHeight + infoboxHeight));
            }
            xDeltaCalc = function() {
                let xDeltaForTopLeft = 0.5 * (triangleWidth) + horizOffset - infoboxWidth;
                return xDeltaForTopLeft > 0 ? xDeltaForTopLeft : 0;
            };
        } else if (adjPlacement === constants.placementLocation.TOP_RIGHT) {
            // quadrant 4
            $(container + " .custom-nib").addClass("infobox-nib-bottom-left");
            yDeltaCalc = function() {
                return(-(triangleHeight + infoboxHeight));
            };
            xDeltaCalc = function() {
                return(-(0.5 * (triangleWidth) + horizOffset) + containerWidth);
            };
        } else if (adjPlacement === constants.placementLocation.TOP) {
            // top
            $(container + " .custom-nib").addClass("infobox-nib-bottom-center");
            if(adjustedPoints.x!==0) {
                $(container + " .custom-nib").css('right', adjustedPoints.x+'px');
            }
            yDeltaCalc = function() {
                return(-(triangleHeight + infoboxHeight));
            }
            xDeltaCalc = function() {
                return((0.5 * containerWidth) - (0.5 * infoboxWidth));
            };
        } else if (adjPlacement === constants.placementLocation.BOTTOM) {
            // bottom
            $(container + " .custom-nib").addClass("infobox-nib-top-center");
            if(adjustedPoints.x!==0) {
                $(container + " .custom-nib").css('right', adjustedPoints.x+'px');
            }
            yDeltaCalc = function() {
                return(triangleHeight + containerHeight);
            };
            xDeltaCalc = function() {
                return((0.5 * containerWidth) - (0.5 * infoboxWidth));
            };

        } else if (adjPlacement === constants.placementLocation.LEFT) {
            // left
            $(container + " .custom-nib").addClass("infobox-nib-right-center");
            if(adjustedPoints.y!==0) {
                $(container + " .custom-nib").css('bottom', adjustedPoints.y+'px');
            }
            yDeltaCalc = function() {
                return((containerHeight / 2) - (infoboxHeight / 2));
            };
            xDeltaCalc = function() {
                return(-(infoboxWidth + triangleWidth));
            };
        } else if (adjPlacement === constants.placementLocation.RIGHT) {
            // right
            $(container + " .custom-nib").addClass("infobox-nib-left-center");
            if(adjustedPoints.y!==0) {
                $(container + " .custom-nib").css('bottom', adjustedPoints.y+'px');
            }
            yDeltaCalc = function() {
                return((containerHeight / 2) - (infoboxHeight / 2));
            };
            xDeltaCalc = function() {
                return((containerWidth + triangleWidth));
            };
        }

        if (options.pointerType === constants.pointerType.NONE) {
            $(container + '  .custom-nib').hide();
        }

        // Cause we can only calculate the triangle after the css class has been added,
        // but define the calculation in the conditional.. Try that in Java
        var triangleHeight = parseInt($(container + ' .custom-nib').css('height'));
        var triangleWidth = parseInt($(container + ' .custom-nib').css('width'));
        xDelta = xDeltaCalc();
        yDelta = yDeltaCalc();

        // Sometimes the Infobox is defined before the actual target is visible.
        // Now worries.  It will be re-drawn on the initital click.
        var targetPosition = $(options.targetSelector).offset();
        if (typeof targetPosition == "undefined") {
            adjustedPoints.x = 0;
            adjustedPoints.y = 0;
        } else {
            adjustedPoints.x = adjustedPoints.x + targetPosition.left + xDelta; // TODO + options.offsetAdjustment.x
            adjustedPoints.y = adjustedPoints.y + targetPosition.top + yDelta;
        }

        return adjustedPoints;

    }

    function handleSmartPlacement(target) {
        // Get center pos of target.

        var targetOffset = $(target).offset();
        targetOffset.top += $(target).height() / 2;
        targetOffset.left += $(target).width() / 2;

        var docWidth = $(window).width();
        var docHeight = $(window).height();

        var placementLocation = "NA";
        // First get the Quadrant of the Target
        if (targetOffset.top <= docHeight / 2) {
            //Top Sector
            if (targetOffset.left <= docWidth / 2) {
                //Top Left Sector / Q1
                placementLocation = constants.placementLocation.BOTTOM_RIGHT;
            } else {
                //Top Right Sector / Q2
                placementLocation = constants.placementLocation.BOTTOM_LEFT;
            }
        } else {
            //Bottom Sector
            if (targetOffset.left <= docWidth / 2) {
                //Bottom Left Sector / Q4
                placementLocation = constants.placementLocation.TOP_RIGHT;
            } else {
                //Top Right Sector / Q3
                placementLocation = constants.placementLocation.TOP_LEFT;
            }

        }

        return placementLocation;
    }

    // TODO Add the actual event. . hide/show/destroy on the target selector!
    // this just sets the flag on hover.
    function attachInfoboxEvents(options) {

        var eventType = options.triggerEvent;
        var targetSelector = options.targetSelector;
        var infoboxContainer = targetSelector + "-custom-infobox";

        // handle click away
        $(document).click(function(e)
        {

            // Check to see if target is within infobox.
            var cleanTargetName = options.targetSelector.replace(/^#/, "");
            var cleanInfoboxName = infoboxContainer.replace(/^#/, "");

            var targetId = e.target.getAttribute("id");
            if (e == null || targetId === cleanTargetName || targetId === cleanInfoboxName) {
                return;
            }


            var containsTarget = false;

            var containsInfobox = false;
            try {
                containsTarget = $.contains(document.getElementById(cleanTargetName), e.target)
            } catch (x) {
            }
            try {
                containsInfobox = $.contains(document.getElementById(cleanInfoboxName), e.target)
            } catch (x) {
            }
            if (!(containsTarget || containsInfobox)) {
                $(targetSelector + "-custom-infobox").hide();
                options.visible = false;
            }
        });

        // handle close event
        $(infoboxContainer + " .close").click(function()
        {
            $(targetSelector + "-custom-infobox").hide();
            options.visible = false;
        });

        if (eventType === constants.triggerEvent.HOVER) {
            $(targetSelector).hover(
                    function() {
                        // GESK -- For Safety -- re-size just before click, as DOM could have changed.
                        // e.g. bug found by Kevinfor Payment & Billing ! when a radio button changes the DOM
                        // location of the target, so the initial calculations are OFF!!
                        // TODO -- add speed to check to see if pos changes..
                        var adjustedPoints = adjustInfoboxPosition(options);
                        //var target = activeInfoboxes[i].targetSelector;
                        $(targetSelector + "-custom-infobox").css({
                            'left': adjustedPoints.x,
                            'top': adjustedPoints.y
                        });
                        // GESK
                        $(targetSelector + "-custom-infobox").show();
                        var containerId = $(this).attr('id');
                        //console.log('hover ' + containerId);
                    },
                    function() {
                        if (options.activeWhenOverInfobox === true) {
                            setTimeout(function() {
                                if (options.hover === false) {
                                    $(targetSelector + "-custom-infobox").hide();
                                    options.visible = false;
                                }

                            }, 300);

                        } else {
                            $(targetSelector + "-custom-infobox").hide();
                            options.visible = false;
                            var containerId = $(this).attr('id');
                            //console.log('un hover ' + containerId);
                        }
                    }
            );

        } else if (eventType === constants.triggerEvent.CLICK) {
            $(targetSelector).click(
                    function() {

                        // GESK -- For Safety -- re-size just before click, as DOM could have changed.
                        // e.g. bug found by Kevin for Payment & Billing ! when a radio button changes the DOM
                        // location of the target, so the initial calculations are OFF!!
                        // TODO -- add speed to check to see if pos changes..
                        var adjustedPoints = adjustInfoboxPosition(options);
                        //var target = activeInfoboxes[i].targetSelector;
                        $(targetSelector + "-custom-infobox").css({
                            'left': adjustedPoints.x,
                            'top': adjustedPoints.y
                        });
                        // GESK

                        $(targetSelector + "-custom-infobox").toggle();
                        var containerId = $(this).attr('id');
                        if ($(targetSelector + "-custom-infobox").is(':visible')) {
                            options.visible = true;
                        } else {
                            options.visible = false;
                        }

                    });

        }

        // Support Feature: Keep Infobox open When move mouse from Target Element -> Actual Infobox.
        if (options.activeWhenOverInfobox === true && eventType !== constants.triggerEvent.CLICK) {
            $(targetSelector + "-custom-infobox").hover(
                    function() {
                        options.hover = true;
                    },
                    function() {
                        options.hover = false;
                        $(targetSelector + "-custom-infobox").hide();
                        options.visible = false;
                    });
        }

        // Handle Window re-size -> Cycle thru all active infoboxes, and adjust postition.
        $(window).resize(function() {
            theResize();
        });

        /*
         $(window).scroll(function(){
         theResize();
         });
         */
    }

    function theResize() {
        for (i = 0; i < activeInfoboxes.length; i++) {
            var target = activeInfoboxes[i].targetSelector;
            $(target + "-custom-infobox").show(); // TODO : Need to show infobox now we can get the size info to place correct. do workaround!
            var adjustedPoints = adjustInfoboxPosition(activeInfoboxes[i]);
            $(target + "-custom-infobox").css({
                'left': adjustedPoints.x,
                'top': adjustedPoints.y
            });
            if (activeInfoboxes[i].visible == false) {
                $(target + "-custom-infobox").hide(); // In case it was clicked open
            }
        }
    }

    function destroyInfobox(containerId) {
        if (hoverMiniProfileContainer === false) {

            $(containerId).unbind('mouseenter mouseleave');
            $(containerId).unbind('click');

            id = utils.prependHashIfNeeded(containerId);
            $(id + "-custom-infobox").remove(); // till be re-org CSS
            deleteInfoboxByTargetSelector(containerId);
        }
    }

    function getInfoboxByTargetSelector(targetSelector) {
        for (i = 0; i < activeInfoboxes.length; i++) {
            if (activeInfoboxes[i].targetSelector === targetSelector) {
                return activeInfoboxes[i];
            }
        }
        return null;
    }

    function deleteInfoboxByTargetSelector(targetSelector) {
        for (i = 0; i < activeInfoboxes.length; i++) {
            if (activeInfoboxes[i].targetSelector === targetSelector) {
                activeInfoboxes.splice(i, 1);
                return;
            }
        }
        // Because the Flash Calls this all the time!
        if (targetSelector != "#chartInfoboxContainer") {
            //console.log(targetSelector + ": Not found in activeInfoboxes[]");

        }
    }

    /***********************************************************
     * Public API
     ***********************************************************/

    return {
        init: function() {
            // Allow External Init.
            init();
        },
        makeInfobox: function(targetSelector, htmlFile, htmlId, placementLocation, triggerEvent, width, height) {
            makeInfobox(targetSelector, htmlFile, htmlId, placementLocation, triggerEvent, width, height);
        },
        renderInfobox: function(containerId, dataObj, points, quadrant, template) {
            renderInfobox(containerId, dataObj, points, quadrant, template);
        },
        render: function(options) {
            render(options);
        },
        destroyInfobox: function(containerId) {
            destroyInfobox(containerId);
        },
        // Used by Manual mode applications like Flash Integration
        isHover: function(containerId) {
            var infoboxOptions = getInfoboxByTargetSelector(containerId);
            if (infoboxOptions != null) {
                return infoboxOptions.hover;
            }
            else
                return false;
        },
        // Place holder for Reference
        options: {
            triggerEvent: 'hover', // VALUES: hover, click
            targetSelector: '#targetId', // For now, must be an ID selector. -- Used to build name.
            placementLocation: "top-left", // Placement relative to target VALUES:  top, right, bottom, left, top-right, top-left, bottom-left, bottom-right
            offsetAdjustment: {
                "x": 0,
                "y": 0
            }, // added offset adjustments from default placement.
            pointerType: "default", // The triangle pointer.  For now either VALUES:  defalt, none
            templateName: "chartInfobox", // the html template file to use.
            // dataObj: anything you want to dynamically populate the template, an be empty too, e.g. {}
            templateDataObj: {
                "Title": "Gift Card",
                "imgSrc1": "/images/giftHeaderBg.jpeg",
                "imgSrc2": "/images/giftBodyBg.jpeg",
                "price": 125
            },
            activeWhenOverInfobox: true  // e.g. When you move from the target to the infobox, does it stay up.
        },
        /***********************************************************************
         *
         * API Support -(Ancilary )
         *  -Give the dev team some tools for support and prototypeing
         *  -Basic Workflow:
         *   -- >var options = infobox.example();
         *      >var options = infobox.getOptions("#testInfobox");
         *      >options.placementLocation = 7 // .. if you memorized the numbers, OR....
         *      >options.placementLocation = infobox.constants.TOP_LEFT // the console will help find values :)
         *      >infobox.setOptions(options);  // basically destroyes, and creates new infobox with your options..
         *
         ***********************************************************************/
        constants: constants,
        getInfoboxes: function() {
            return activeInfoboxes;
        },
        getExampleOptions: function() {
            return this.getOptions("#testInfobox");
        },
        getOptions: function(targetSelector) {
            var options = getInfoboxByTargetSelector(targetSelector);
            return options;
        },
        setOptions: function(options) {
            // Note, target is embedded in the Options!
            // Basically, destroyes pop over, and re-renders with new options
            destroyInfobox(options.targetSelector);
            render(options);

        },
        freeze: function(targetSelector) {
            // Remove the mouse out event so you can test.. OR.. just convert the sucker to CLICK..

        },
        help: function(command) {


            if (command != null && command === 'modify') {
                console.log("Example to control with the Venue Infobox on the Production page");
                console.log("=============================================================");
                console.log(">infobox.getInfoboxes(); ");
                console.log(">var venueOptions = infobox.getOptions('#tp_venueGuide'); ");
                console.log(">venueOptions.triggerEvent = infobox.constants.triggerEvent.HOVER;");
                console.log(">venueOptions.placementLocation = infobox.constants.BOTTOM_RIGHT;");
                console.log(">infobox.setOptions(venueOptions);");
            } else {
                console.log("--NEW Simple Infobox-- no object.  Just call infobox.makePopver(), Use Working Example Below (width, and height are optional e.g. 400px, 500px)");
                console.log("==================================================================================================================\n");
                console.log('infobox.makeInfobox("#greg", "/res/scripts/templates/infoboxes/htmlTemplates.htm", "#template1", infobox.constants.placementLocation.TOP_RIGHT, "click", "400px", "500px");\n');
                console.log("==================================================================================================================\n");
                console.log("the Infobox is deployed by creating an options object, and coding infobox.render(optionsObject). ");
                console.log("\nA 2 Step Example of creating a Infobox and rendering it on an element with an ID=testInfobox is listed below:");
                console.log("==================================================================================================================");
                console.log("\n1. Build an Options Object.  Note: template name 'exampleInfobox' must correspond to an actual html template you create ");
                console.log("e.g. /scripts/widgets/infobox/exampleInfobox.tpl.htm");
                console.log("==========================================================================================================================");
                console.log("var giftCardData =  {");
                console.log("	'image_src' : '/v3/images/widget/custom/giftECard2.gif',");
                console.log("};");
                console.log("var	giftCardOptions = {");
                console.log("	triggerEvent : infobox.constants.triggerEvent.HOVER,");
                console.log("	targetSelector: '#testInfobox', ");
                console.log("	placementLocation: infobox.constants.placementLocation.AUTO, ");
                console.log("	offsetAdjustment: { 'x': 0, 'y':0}, ");
                console.log("	pointerType: infobox.constants.pointerType.DEFAULT, ");
                console.log("	templateName: 'exampleInfobox',  ");
                console.log("	width: '400px',");
                console.log("	height: '300px',");
                console.log("	customClasses: 'selected main-paragraph',");
                console.log("	showCloseButton: true,");
                console.log("	activeWhenOverInfobox: true, ");
                console.log("	data_txt: 'Put data text here', ");
                console.log("	html_txt: 'Put html txt here' ");
                console.log('	html_txt: utils.getTemplateById("/res/scripts/templates/infoboxes/htmlTemplates.htm", "#template2") ');
                console.log("};");
                console.log("\n2. Call infobox.render() to activate it.");
                console.log("==========================================================================================================================");
                console.log("infobox.render(giftCardOptions);");

                console.log("\n\nAn example code Generator is provided for Prototyping: ");
                console.log("[just cut and paste commands below (one at a time)  into the console to generate an example target and infobox on your current page..] ");
                console.log("==========================================================================================================================");
                console.log(">var options = infobox.example('q3');");
                console.log(">options.placementLocation = infobox.constants.placementLocation.AUTO");
                console.log(">options.triggerEvent = infobox.constants.triggerEvent.CLICK;");
                console.log(">infobox.setOptions(options);");
                console.log(">infobox.exampleDestroy();");
            }
        },
        /***********************************************************************
         * TESTING & Demo Support
         *
         ***********************************************************************/
        exampleDestroy: function() {
            $("#testInfobox").parent().remove(); // Testabality
            destroyInfobox("#testInfobox");
        },
        destroyExample: function() {
            this.exampleDestroy();
        },
        example: function(quad) {

            var targetPos = "top:0; left:0; ";
            if ((typeof quad) != 'undefined') {
                if (quad === 'q2') {
                    targetPos = "top:0; right:0; ";
                } else if (quad === 'q3') {
                    targetPos = "bottom:0; right:0; ";
                } else if (quad === 'q4') {
                    targetPos = "bottom:0; left:0; ";
                }
            }

            $("#testInfobox").remove(); // Testabality
            destroyInfobox("#testInfobox");

            // Create the HTML Test Element to hang off of.
            var exampleTargetElement = '<div style="position:fixed; ' + targetPos + ' z-index:50000;">  \
					<a id="testInfobox" style="padding:5px; font-size:14px;">Infobox</a> \
				</div>'
            $('body').prepend(exampleTargetElement);


            var exampleOptions = {
                triggerEvent: infobox.constants.triggerEvent.CLICK,
                targetSelector: '#testInfobox',
                placementLocation: infobox.constants.placementLocation.AUTO,
                offsetAdjustment: {
                    "x": 0,
                    "y": 0
                },
                pointerType: infobox.constants.pointerType.DEFAULT,
                templateName: "exampleInfobox",
                customClasses: 'selected left',
                showCloseButton: true,
                activeWhenOverInfobox: true,
                data_txt: 'Hi, this is the data text',
                ///html_txt: '<h2>Hi, this is html text </h2>'
                html_txt: utils.getTemplateById("/res/scripts/templates/infoboxes/htmlTemplates.htm", "#template2")
            };
            render(exampleOptions);
            return exampleOptions;
        }



    }

    /***********************************************************
     * END Public API
     ***********************************************************/


}();



