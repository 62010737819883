function widgetLoadingIndicator(selector) {
    $(selector + " .widgetContent").html('<div class="widgetLoading"><img src="/v3/images/common/loadingAnimation16x16.gif" alt="loading" width="16" height="16"></div>');
}

// ADVANCED SEARCH

var advancedSearchRegionId;

$(document).ready(function() {

    try {
        if ($(".advancedSearchEventType").length > 0) {
            if ($('.advancedSearchRegionFilter').length) {
                appendRegionOptions($(".advancedSearchRegionFilter"), advancedSearchRegionId);
            }

            $("input[name='dateFrom']").datepicker("setDate", new Date());
            $("input[name='dateThru']").datepicker("setDate", addDays(new Date(), 30));
                        
            $(".advancedSearchEventType").change(function() {
                $('.advanced-search-categories-loading').show();
                $('.advancedSearchCategories').hide();
                //$('.eventCategoryFilter').select("destroy");
                $("select[name='eventCategoryFilter']").select("destroy");

                $.get('/widget/AdvancedSearch.action',
                        "showCategoriesByType=&" + $(".advancedSearch form").serialize(),
                        function(data) {
                            $('.advanced-search-categories-loading').hide();
                            $('.advancedSearchCategories').show();
                            $('.advancedSearchCategories').html(data);
                            selectOption(".advancedSearchCategories", categoryId);
                            //$('.eventCategoryFilter').select();
                            $("select[name='eventCategoryFilter']").select();
                        }
                );
            });


            selectOption(".advancedSearchEventType", eventType);
            $(".advancedSearchEventType").change();

            //HIDE DATE PICKER UNTIL SELECTED
            $('.advancedSearchDatePickers').hide();

            $(".advancedSearchDateOptions").change(function() {
                if ($(this).val() == "custom") {
                    $(".advancedSearchDatePickers").show();
                }
                else {
                    $(".advancedSearchDatePickers").hide();
                }
            });

            $(".advanced-search form").submit(function() {
                if ($(".advancedSearch input[name='searchTerm']").val() == $(".advancedSearch input[name='searchTerm']").attr("title")) {
                    $(".advancedSearch input[name='searchTerm']").val("");
                }

                if ($(".advancedSearchDateOptions").val() == "custom") {
                    //date already set by daterangepicker
                }else if ($(".advancedSearchDateOptions").val() == "weekend"){
                    var weekend = getWeekendDates(new Date());
                    $("input[name='dateFrom']").datepicker("setDate",weekend[0]);
                    $("input[name='dateThru']").datepicker("setDate",weekend[1]);
                }else if ($(".advancedSearchDateOptions").val() == "any") {
                    $("input[name='dateFrom']").val("");
                    $("input[name='dateThru']").val("");
                }else {
                    $("input[name='dateFrom']").datepicker("setDate", new Date());
                    $("input[name='dateThru']").datepicker("setDate", addDays(new Date(), $(".advancedSearchDateOptions").val()));
                }
            })


            jQuery("#dateRangeG").daterangepicker({}, function(start, end) {
                //Note, start, and end are not instanceof Date.
                $("input:hidden[name=dateFrom]").val(start.format('M/D/YY'));
                $("input:hidden[name=dateThru]").val(end.format('M/D/YY'));

            });
        }
    } catch (x) {

    }

});

// END ADVANCED SEARCH


// IMAGE GRID

function handleImageGridPaginationClick(pageIndex, paginationContainer) {
    var widget = paginationContainer.parents(".widget");
    var itemsPerPage = $("input[name='itemsPerPage']", widget).val();
    var firstElementIndex = pageIndex * itemsPerPage;
    var lastElementIndex = Math.min(firstElementIndex * 1 + itemsPerPage * 1, $("li.geoGridItem", widget).size());
    $("li.geoGridItem", widget).hide();
    $("li.geoGridItem", widget).slice(firstElementIndex, lastElementIndex).show();
    $(".widgetPagingItemRange", widget).html(firstElementIndex + 1 + " - " + lastElementIndex + " <span>of</span> " + $("li.geoGridItem", widget).size());
}

function imageGridUpdateRegion() {
    if (regionId > 0) {
        widgetLoadingIndicator(".regionalImageGrid");
        initRegionalImageGrid();
        $(".regionalImageGrid").show();
    }
    else {
        $(".regionalImageGrid").hide();
    }
}

function imageGridViewAllEvents(eventType) {
    location = "/Search.action?eventTypeFilter=" + eventType + "&regionId=" + regionId;
}

// END IMAGE GRID


// HOUSE

function houseToggleAccordion(houseItem, soloMode, widgetId) {
    var expanded = houseItem.is(".expanded");
    if (soloMode) {
        $(".houseItem", "#" + widgetId).has(".houseItemAccordionIcon").removeClass("expanded");
        $(".houseItem", "#" + widgetId).has(".houseItemAccordionIcon").addClass("collapsed");
    }
    if (expanded) {
        houseItem.removeClass("expanded");
        houseItem.addClass("collapsed");
    }
    else {
        houseItem.removeClass("collapsed");
        houseItem.addClass("expanded");
    }
}

// END HOUSE


/* SLIDER
 
 function sliderInit(scrollableObject, autoplay) {
 var scroller = scrollableObject.scrollable({
 circular: true,
 next: ".scrollableNext",
 prev: ".scrollablePrev",
 disabledClass: "scrollableDisabled"
 }).autoscroll({
 interval: 5000,
 autoplay: autoplay
 });
 }
 
 // END SLIDER */


// TICKET ALERT

function productionsSignUpForAlert() {
    $(".productionsAlert button").attr("onclick", "");
    $(".productionsAlertLoading").show();
    if ($(".productions input[name='emailAddress']").val() == $(".productions input[name='emailAddress']").attr("title")) {
        $(".productions input[name='emailAddress']").val("");
    }
    $.ajax({
        url: "/widget/TicketAlert.action",
        data: "signUpForAlert&" + $(".productions form").serialize(),
        success: function(data) {
            $(".productionsAlert.pal").replaceWith(data);
        }
    });
}

// END TICKET ALERT


// BANNER
$(document).ready(function() {
    $(".bannerMoreLink").click(function() {
        $(".bannerCollapsedContent").hide();
        $(".bannerExpandedContent").show();
    });

    $(".bannerLessLink").click(function() {
        $(".bannerExpandedContent").hide();
        $(".bannerCollapsedContent").show();
    });
});

// END BANNER


// PRODUCTION FILTERS

var productionsAjaxMethod;

$(document).ready(function() {
    if ($(".productionsTable").length == 0 && productionsAjaxMethod == null) {
        $(".productionFilters").hide();
    }
});

// END PRODUCTION FILTERS


// NAV

function navExpand(widgetId) {
    $('#' + widgetId + ' .collapsed-nav-entry').show();
    $('#' + widgetId + ' .nav-less').show();
    $('#' + widgetId + ' .nav-more').hide();
}

function navCollapse(widgetId) {
    $('#' + widgetId + ' .collapsed-nav-entry').hide();
    $('#' + widgetId + ' .nav-less').hide();
    $('#' + widgetId + ' .nav-more').show();
}

// END NAV


// VENUES BY COUNTRY

$(document).ready(function() {
    $(".venuesByCountryQuickNav").change(function() {
        var elementToScrollTo = $("#venuesByCountryCode_" + $("option:selected", this).val());
        $('.venuesByCountry .widgetContent').scrollTo(elementToScrollTo, 200);
        selectOption(".venuesByCountryQuickNav", "");
    });
});

// END VENUES BY COUNTRY


// VENUES BY STATE

$(document).ready(function() {
    $(".venuesByStateQuickNav a").click(function() {
        var elementToScrollTo = $("div[title='venuesByStateLetter_" + $(this).attr("title") + "']");
        $('.venuesByState .widgetContent').scrollTo(elementToScrollTo, 200);
    });
});

// END VENUES BY COUNTRY


// PRICE ALERTS
/*
 $(document).ready(function() {
 $(".priceAlertsDeleteDialog").dialog({
 title: "Delete price alert",
 modal: true,
 draggable: false,
 resizable: false,
 autoOpen: false
 });
 });
 
 function priceAlertsOpenDeleteDialog(priceAlertId, priceAlertToken, priceAlertName) {
 $(".priceAlertsDeleteName").html(priceAlertName);
 $(".priceAlertsDeleteDialog input[name='priceAlert.id']").val(priceAlertId);
 $(".priceAlertsDeleteDialog input[name='priceAlert.token']").val(priceAlertToken);
 $(".priceAlertsDeleteDialog").dialog("open");
 }
 
 // END PRICE ALERTS
 
 
 // EVENT ALERTS
 
 $(document).ready(function() {
 $(".eventAlertsDeleteDialog").dialog({
 title: "Delete event alert",
 modal: true,
 draggable: false,
 resizable: false,
 autoOpen: false
 });
 });
 
 function eventAlertsOpenDeleteDialog(eventAlertId, eventAlertName) {
 $(".eventAlertsDeleteName").html(eventAlertName);
 $(".eventAlertsDeleteDialog input[name='eventAlert.id']").val(eventAlertId);
 $(".eventAlertsDeleteDialog").dialog("open");
 }
 */
// END EVENT ALERTS


// TABLE PAGING

//var tablePagingUrl = "";
var tablePagingLimit = 10;
var tablePagingPageCount = 0;
var tablePagingUrlType = "default";

function tableInitPagination(url, limit, pageCount, totalCount, offset, widgetId, urlType, shortPaging) {
    //tablePagingUrl = url;
    tablePagingLimit = limit;
    tablePagingPageCount = pageCount;
    tablePagingUrlType = urlType;

    var numEdgeEntries = 1;
    var numDisplayEntries = 5;
    var nextText = "&gt;";
    var prevText = "&lt;";
    if(shortPaging==="true") {
        numEdgeEntries = 0;
        numDisplayEntries = 0;
        nextText = "Next";
        prevText = "Prev";
    }

    $("#" + widgetId + " .widgetPagingLinks").pagination(totalCount, {
        items_per_page: limit,
        current_page: offset / limit,
        callback: tablePagingHandlePageClick,
        next_text: nextText,
        prev_text: prevText,
        num_edge_entries: numEdgeEntries,
        link_to: url,
        num_display_entries: numDisplayEntries
    });
}

function tablePagingHandlePageClick(pageIndex, paginationContainer) {
    var widget = paginationContainer.parents(".widget");
    var tablePagingUrl = this.link_to;
    var itemsPerPage = $("input[name='itemsPerPage']", widget).val();
    var firstElementIndex = pageIndex * itemsPerPage;
    if (tablePagingUrlType == "simple") {
        location = tablePagingUrl + "page/" + (pageIndex + 1) + "/";
        if (tablePagingUrl.indexOf("/page") != -1) {
            window.location = tablePagingUrl.substring(0, tablePagingUrl.lastIndexOf("/page")) + "/page/" + (pageIndex + 1) + "/";
        }
    }
    else {
        window.location = tablePagingUrl + "&limit=" + tablePagingLimit + "&offset=" + (firstElementIndex);
    }
    return false;
}

// END TABLE PAGING



// TABLE ITEM TOGGLER

$(document).ready(function() {
    $(".tableItemToggleIcon").click(function() {
        tableToggler($(this))
    });
});



function tableToggler(toggleLink) {

    var tableItem = toggleLink.parents('.tableItem');
    var expanded = tableItem.is(".expanded");

    if (expanded) {
        tableItem.removeClass("expanded");
        tableItem.addClass("collapsed");
    }
    else {
        tableItem.removeClass("collapsed");
        tableItem.addClass("expanded");
    }
}


// BLOG ARTICLE

$(document).ready(function() {
    $(".blogArticleContent p:first").prepend($(".blogArticleHeadlineA"));
});

// END BLOG ARTICLE


// SPORTS SCORES

var gameRows;
var minGames;
$(document).ready(function() {
    gameRows = $('div.sportsScoresWidget .gameRow');
    var numGames = gameRows.length;
    if (numGames > 0) {
        $(gameRows[numGames - 1]).toggleClass('lastVisibleGame', true);
        if (numGames > minGames) {
            hideAdditionalGames();
        }
        else {
            $('#scoresMoreLink').hide();
            $('#scoresLessLink').hide();
        }
    }
});

function hideAdditionalGames() {
    gameRows.each(function(index) {
        if (index >= minGames) {
            $(this).hide();
        }
        else if (index == (minGames - 1)) {
            $(this).toggleClass('lastVisibleGame', true);
        }
    });
    $('#scoresMoreLink').show();
    $('#scoresLessLink').hide();
}

function showAdditionalGames() {
    gameRows.each(function(index) {
        if (index >= minGames) {
            $(this).show();
        }
        else if (index == (minGames - 1)) {
            $(this).toggleClass('lastVisibleGame', false);
        }
    });
    $('#scoresMoreLink').hide();
    $('#scoresLessLink').show();
}


// END SPORTS SCORES
